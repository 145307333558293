
//
// CoreUI default colors
//

$white:  #fff;
$black:  #000015;

$gray-base:  #23303d;
$gray-100:   #E9e9e9;
$gray-200:   #d8dbe0;
$gray-300:   #c4c9d0;
$gray-400:   #b1b7c1;
$gray-500:   #9da5b1;
$gray-600:   #8a93a2;
$gray-700:   #768192;
$gray-800:   #23303d;
$gray-900:   #4f5d73;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
    (
      "100":  $gray-100,
      "200":  $gray-200,
      "300":  $gray-300,
      "400":  $gray-400,
      "500":  $gray-500,
      "600":  $gray-600,
      "700":  $gray-700,
      "800":  $gray-800,
      "900":  $gray-900
    ),
    $grays
);

$primary-base:  #321fdb;
$primary-100:   #eae9fb;
$primary-200:   #d6d2fb;
$primary-300:   #c1bcf4;
$primary-400:   #ada5f1;
$primary-500:   #988fed;
$primary-600:   #8478ea;
$primary-700:   #6f62e6;
$primary-800:   #5b4ce2;
$primary-900:   #4635df;

$primary-dark:  #351d75;
$primary:       #25c6e3;
$primary-50:    #988fed;
$primary-25:    #ccc7f6;
$violet:        #7B3381;


$secondary-base:  #23303d;

$secondary-100:   #ebedef;
$secondary-200:   #d8dbe0;
$secondary-300:   #c4c9d0;
$secondary-400:   #b1b7c1;
$secondary-500:   #9da5b1;
$secondary-600:   #8a93a2;
$secondary-700:   #768192;
$secondary-800:   #636f83;
$secondary-900:   #4f5d73;

$secondary-dark:  #212233;
$secondary:       #3c4b64;
$secondary-50:    #9da5b1;
$secondary-25:    #ced2d8;


$danger-dark:  #d93737;
$danger:       #e55353;
$danger-50:    #f2a9a9;
$danger-25:    #f9d4d4;

$info-dark:  #2982cc;
$info:       #39f;
$info-50:    #80c6ff;
$info-25:    #c0e6ff;

$success-dark:  #1b9e3e;
$success:       #56c3a3;
$success-50:    #96dbad;
$success-25:    #cbedd6;

$warning-dark:  #f6960b;
$warning:       #f6c163;
$warning-50:    #fcd88a;
$warning-25:    #feecc5;
$active-color:  #549764;

$light:  $gray-100;
$dark:   $gray-800;

$pink:  #e83e8c;

$AimhiPrimary:          #549761;
$AimhiHero:             #351D75;
$AimhiSecondaryYellow:  #F2E442;
$AimhiSecondary:        #25C6E3;
$AimhiInfo:             #3B74BE;
$AimhiSuccess:          #3A7D21;
$AimhiWarning:          #B6451D;
$AimhiDanger:           #B42A19;
$AimhiSystem:           #FDFE54;
$AimhiTextLightMuted:   #757070;
$AimhiTextLightNeutral: #23303D;
$AimhiLight:            #F4F4F7;
$AimhiBorder:           #DEDEE1;
$AimhiOrange:           #FA7503;
$AimhiPowderBlue:       #b1c2c8;


// scss-docs-start theme-colors-map
$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
      "aimhi-orange": $AimhiOrange,
      "aimhi-primary": $AimhiPrimary,
      "aimhi-hero": $AimhiHero,
      "aimhi-secondary-yellow": $AimhiSecondaryYellow,
      "aimhi-secondary": $AimhiSecondary,
      "aimhi-info": $AimhiInfo,
      "aimhi-success": $AimhiSuccess,
      "aimhi-warning": $AimhiWarning,
      "aimhi-danger": $AimhiDanger,
      "aimhi-system": $AimhiSystem,
      "aimhi-textlight-muted": $AimhiTextLightMuted,
      "aimhi-textlight-neutral": $AimhiTextLightNeutral,
      "aimhi-light": $AimhiLight,
      "aimhi-border": $AimhiBorder,
      "aimhi-powderblue": $AimhiPowderBlue,
      "success-dark": $success-dark,
      "primary":    $primary,
      "secondary":  $secondary-25,
      "success":    $success,
      "info":       $info,
      "warning":    $warning,
      "danger":     $danger,
      "light":      $light,
      "dark":       $dark,
      "secondary_base":$secondary-base,
      "primary_dark": $primary-dark,
      "active-color": $active-color,
      "violet" :$violet,
      "white" :$white,
    ),
    $theme-colors
);
// scss-docs-end theme-colors-map

// Set a specific jump point for requesting color jumps
$theme-color-interval:  8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:   $gray-900;
$yiq-text-light:  $white;

$yiq-theme-map: ();
// stylelint-disable-next-line scss/dollar-variable-default
$yiq-theme-map: map-merge(
    (
      default: (
        "yiq-text-dark":  $yiq-text-dark,
        "yiq-text-light":  $yiq-text-light
      )
    ),
    $yiq-theme-map
);

// TODO: Move to better place
// Characters which are escaped by the escape-svg function
$escaped-characters: (
    ("<","%3c"),
    (">","%3e"),
    ("#","%23"),
    ("(","%28"),
    (")","%29"),
);
// Variable overrides

$body-bg:     $white;
$body-color:  $gray-base;

$pagination-color: #23303D;
$pagination-hover-color: #23303D;
  //$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              #23303d;
//$pagination-active-border-color:    $component-active-bg !default;
