.font {
  font: normal normal bold Roboto;
}
.Title {
  text-align: left;
  font: normal normal bold 24px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
  font-weight: 700;
}

.Description {
  text-align: left;
  font: italic normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.nopadding {
  padding: 5px !important;
  margin: 5px !important;
}

.card-header-project {
  border-bottom: white;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.card-label {
  white-space: nowrap;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #23303dc3;
  opacity: 1;
}

.card-value {
  white-space: nowrap;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.start {
  white-space: nowrap;
  text-align: left;
  font-size: 8px;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.end {
  white-space: nowrap;
  text-align: right;
  font-size: 8px;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.box-shadow {
  box-shadow: 0px 3px 6px #00000029;
  /* border: 1px solid #23303d; */
  border-style: solid;
  border-width: 1.5px;
  border-color: #23303d9a;
  border-radius: 5px !important;
}

.project-img-size-project {
  width: 98px;
  height: 92px;
  object-fit: contain;
}

.font-with-responsive {
  display: inline-block;
  width: 80%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.input-design {
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.members {
  background: #ffffff;
  border: 0.5px solid #23303d;
  color: #23303d;
  font: normal normal normal 12px/14px Roboto;
  border-radius: 4px;
  padding: 5px;
  width: 95%;
}

.font-responsive {
  display: inline-block;
  width: 85%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media screen and (max-width: 480px) {
  .project-img-size-project {
    width: 100%;
    height: 90%;
  }

  .card-label {
    /* white-space: nowrap; */
    text-align: left;
    font: normal normal normal 14px/18px Roboto;
    letter-spacing: 0px;
    color: #23303dc3;
    opacity: 1;
  }

  .card-value-project {
    white-space: nowrap;
    text-align: left;
    font: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
  }

  .start {
    white-space: nowrap;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
  }

  .end {
    white-space: nowrap;
    text-align: right;
    font-size: 12px;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
  }

  .margin-mobile {
    margin-top: 20px;
  }
}
