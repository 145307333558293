// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";



.c-active {
    background: #549764 !important;
}

@media (hover: hover), (-ms-high-contrast: none) {
    .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        color: #fff;
        background: #549764 !important;
    }
}