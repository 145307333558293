html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 60px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
.calculator {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 306px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  overflow-y: auto;
}

.calculator.open {
  transform: translateX(0);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
}

.sidebar {
  width: 250px; /* Adjust the width as needed */
  background: #333;
  color: white;
  padding: 20px;
}

.toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #333;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.calculator-title {
  text-align: center;
}

.sow-group {
  padding-top: 24px;
  padding-left: 28px;
  text-align: start;
}

.sow-name {
  font: bold Roboto;
  font-size: 20px;
  font-weight: 700;
}

.work-name {
  font: bold Roboto;
  font-size: 16px;
  font-weight: 700;
}
.calculation-title {
  padding-top: 100px;
  font: bold Roboto;
  font-size: 24px;
  font-weight: 700;
}

.description {
  padding-top: 15px;
  padding-left: 28px;
  padding-right: 28px;
  text-align: start;
}

.description-text {
  font-family: Roboto;
  font-size: 12px;
}

.area-field {
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 12px;
}

.input-wrapper {
  display: flex;
}

.area-textfield {
  width: 145px;
  height: 52px;
  border-radius: 6px 0px 0px 6px;
  border-color: #757070;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
}

.area-textfield.invalid-input {
  border-color: #b42a19;
  box-shadow: 0 0 0 0.2rem rgba(180, 42, 25, 0.25);
}

.area-search-results {
  position: absolute;
  z-index: 100;
  background-color: white;
  width: 145px;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border: 1px solid #dedee1;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}

.area-search-result {
  padding: 5px 10px;
  cursor: pointer;
}
.area-search-result:hover {
  background-color: #3b74be;
  color: white;
}

.area-dropdown {
  max-width: 104px;
  height: 52px;
  font-size: 16px;
  padding-left: 12px;
  background-color: #f4f4f7;
  border-radius: 0px 6px 6px 0px;
  color: #22303d;
  border-color: #757070;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
}

.estimated-textfield {
  max-width: 249px;
  height: 52px;
  border-radius: 6px;
  border-color: #757070;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
}

.estimated-textfield.invalid-input {
  border-color: #b42a19;
  box-shadow: 0 0 0 0.2rem rgba(180, 42, 25, 0.25);
}

.button-container {
  padding-left: 28px;
  padding-right: 28px;
  margin-bottom: 100px;
}

.apply-button {
  width: 239px;
  height: 56px;
  margin-top: 60px;
  background-color: #f4f4f7;
  color: #757070;
  border-radius: 5px;
}

.material-dropdown {
  max-width: 249px;
  height: 52px;
  border-radius: 6px;
  border-color: #757070;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
}
