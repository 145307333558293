table {
  font-family: 'Roboto', sans-serif;
}

.disable_table {
  opacity: 0.5;
  cursor: not-allowed;
}

.Title {
  text-align: left;
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.Description {
  text-align: left;
  font: italic normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.row-label {
  text-align: left;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
  font-size: 21px;
}

.project-border {
  /* border: 0.30000001192092896px solid #23303d5b; */
  border-radius: 0px 5px 5px 5px;
  padding: 0 !important;
  opacity: 1;
}

.projecttabs {
  border: 0.2px solid #23303d6c !important;
  opacity: 1 !important;
  height: 41px !important;
  border-radius: 0 !important;
  color: #23303d !important;
  height: 41px !important;
  width: 122px !important;
  text-align: center !important;
}

.projecttabs-desabled {
  border: 0.2px solid #23303d73 !important;
  opacity: 1 !important;
  border-radius: 0 !important;
  color: #ffffff63 !important;
  background: #23303d48 !important;
  height: 41px !important;
  width: 122px !important;
  text-align: center !important;
  cursor: not-allowed;
}

.projecttabs-active {
  border: 0.2px solid #23303d6c !important;
  opacity: 1 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  background: #23303d !important;
  height: 41px !important;
  width: 122px !important;
  text-align: center !important;
}

.Total-label {
  white-space: nowrap;
  font-size: 12px;
  text-align: left;
  font: normal normal normal Roboto;
  letter-spacing: 0px;
  color: #23303dc3;
  opacity: 1;
}

.Total-value {
  font-size: 14px;
  text-align: right;
  font: normal normal bold Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.nopadding {
  padding: 0px !important;
  margin: 0px !important;
}

.project-start-text {
  text-align: left;
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.project-end-text {
  text-align: right;
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.card-days-left-value {
  font-size: 18px;
  text-align: center;
  font: normal normal bold Roboto;
  letter-spacing: 1px;
  color: #efefef;
  opacity: 1;
  font-weight: bold;
}

.card-days-left-label {
  font-size: 12px;

  text-align: center;
  font: normal normal normal Roboto;
  letter-spacing: 1px;
  color: #efefef;
  opacity: 1;
}

.borderless td,
.borderless th {
  border: none;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #23303d;
}

.total-table-text {
  text-align: left !important;
  font: normal normal medium 16px/21px Roboto !important;
  letter-spacing: 0px !important;
  color: #23303d !important;
  opacity: 1 !important;
}

.project-img-main {
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
  background-size: cover;
  background-position: center;
  opacity: 1;
  border-radius: 5px;
}

.project-img-sub {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
  opacity: 0.5;
  border-radius: 5px;
}

.circle-number {
  width: 25px;
  height: 25px;
  background: #3f5253;
  opacity: 1;
  color: white;
  border-radius: 25px;
  padding: 5px;
  margin-left: 10px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.sow-label {
  text-align: left;
  font: normal normal bold 0.9vw Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
  margin-left: 50px;
}

.report-sow-label {
  text-align: left;
  font: normal normal bold 0.9vw Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
  margin-left: 50px;
}

.status-label {
  text-align: center;
  font: normal normal medium 14px/19px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.task-label {
  font-size: 12px;
  text-align: left;
  font: normal normal normal Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.report-label {
  font-size: 12px;
  text-align: left;
  font: normal normal normal Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.top-label {
  white-space: nowrap;
  font-size: 10px;
  font: normal normal normal Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

.badge-element {
  border-radius: 0px;
  padding: 5px;
  width: 100%;
}

.badge-stats {
  border-radius: 10px;
  padding: 5px;
  width: 85px;
  height: 15;
  font-size: 10px;
  margin-left: -5px;
}

/* .badge-stats {
  border-radius: 10px;
  padding: 5px;
  width: 110px;
  height: 15;
  font-size: 12px;
  margin-left: 5px;
} */

.left-design {
  background: #23303d;
  opacity: 1;
  width: 10px;
  height: 106px;
  padding: 0px !important;
  margin-left: 20px;
}

.left-design-danger {
  background: #b42a19;
  opacity: 1;
  width: 10px;
  height: 106px;
  padding: 0px !important;
  margin-left: 20px;
}

.left-design-warning {
  background: #f6c163;
  opacity: 1;
  width: 10px;
  height: 106px;
  padding: 0px !important;
  margin-left: 20px;
}

.left-design-semiwarning {
  background: #f2e442;
  opacity: 1;
  width: 10px;
  height: 106px;
  padding: 0px !important;
  margin-left: 20px;
}

.left-design-success {
  background: #3a7d21;
  opacity: 1;
  width: 10px;
  height: 106px;
  padding: 0px !important;
  margin-left: 20px;
}

.left-design-report {
  background: #23303d;
  opacity: 1;
  width: 10px;

  padding: 0px !important;
  margin: 0px !important;
  margin-top: 0px !important;
}

.task-card {
  margin-bottom: 1px;
  padding: 10px;
  border-radius: 0px;
  box-shadow: 0px 3px 6px #23303d77;
}

.report-card {
  margin-bottom: 1px;
  padding: 10px;
  border-radius: 0px;
  box-shadow: 0px 3px 6px #23303d77;
}

.report-history-card {
  margin-bottom: 1px;
  /* padding: 10px; */
  border-radius: 0px;
  box-shadow: 0px 3px 6px #23303d77;
}

.table-design {
  border: 1px solid #f4f4f7;
  table-layout: fixed;
  width: 1600px;
  text-align: left;
  overflow: scroll;
  border-collapse: collapse;

  font: normal normal medium 14px/19px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  /* margin-top: 30px; */
  cursor: grab;
  padding: 0px;
}

.table-design thead {
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px;
  background: white;
}

.table-design thead tr {
  background: #f4f4f7;
}

.table-design th {
  width: 200px;
  height: 50px;
}

.table-design tbody tr {
  border-bottom: 1px solid #ccc;
  background: white;
}

.table-design td {
  width: 200px;
  height: 50px;
  text-align: center;
}

/* .table-design th:nth-child(2){text-align: left;} */

.table-design th:nth-child(1) {
  width: 250px;
  height: 50px;
  position: absolute;
  z-index: 1;
  background: inherit;
  padding: 10px;
  text-align: left;
}

.table-design th:nth-child(12) {
  width: 200px;
  height: 50px;
  position: absolute;
  left: 700px;
  z-index: 1;
  background: inherit;
  padding: 10px;
}

.table-design th:nth-child(13) {
  width: 200px;
  height: 50px;
  position: absolute;
  left: 900px;
  z-index: 1;
  background: inherit;
  padding: 10px;
}

.table-design th:nth-child(14) {
  width: 200px;
  height: 50px;
  position: absolute;
  left: 1100px;
  z-index: 1;
  background: inherit;
  padding: 10px;
}

.table-design td:nth-child(2) {
  text-align: left;
}

.table-design td:nth-child(1) {
  /* box-shadow: 5px 0 3px -2px #ccc; */
  width: 250px;
  position: fixed;
  position: absolute;
  z-index: 1;
  background: inherit;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.table-design td:nth-child(12) {
  width: 200px;
  position: fixed;
  position: absolute;
  left: 700px;
  z-index: 1;
  background: inherit;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 60px;
  border-bottom: 1px solid #ccc;
}

.table-design td:nth-child(13) {
  width: 200px;
  position: fixed;
  position: absolute;
  left: 900px;
  z-index: 1;
  background: inherit;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 60px;
  border-bottom: 1px solid #ccc;
}

.table-design td:nth-child(14) {
  width: 200px;
  position: fixed;
  position: absolute;
  left: 1100px;
  z-index: 1;
  background: inherit;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 60px;
  border-bottom: 1px solid #ccc;
}

.disable-table-column {
  opacity: 0.5;
  cursor: not-allowed;
}

/* .table-design td:nth-child(3){
    width: 900px;
} */

.caret::after {
  display: none !important;
}

.table-container {
  margin-top: 10px;
  width: 95%;
  height: 405px;
  overflow-y: hidden;
  overflow-x: hidden;
  /* overflow-x: hidden; */
  margin-left: 20px;
  cursor: grab;
}

/* width */
.table-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.table-container::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  /* border-radius: 10px; */
  background: white;
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background: #7070705b;
  border-radius: 2px;
}

/* Handle on hover */
.table-container::-webkit-scrollbar-thumb:hover {
  background: #707070c0;
}

.table-container:hover {
  overflow-y: auto;
  overflow-x: scroll;
}

.green-freeze {
  width: 2200px;
  height: 25px;
  background: #549761;
  color: white;
  font-size: 15px;
  display: block;
}

.freeze {
  text-align: center;
  position: fixed;
  position: absolute;
  z-index: 1;
  background: inherit;
  padding-left: 10px;
  /* padding: 20px; */
  box-sizing: border-box;
  display: inline;
  padding-left: 50px;
  background: #549761;
  width: 565px;
}

.scroll-span {
  margin-left: 1500px;
}

/* .green-scroll {
    width:580px;
    height: 25px;
    background: #549761;
    color:white;
    font-size: 15px;
    display: inline;
    float: right;
    text-align: center;
    margin-left: 500px;
    overflow: visible;
} */

.input_outline {
  border: 0;
  outline: 0;
}

.input_outline:focus {
  outline: none !important;
}

.input_outline:disabled {
  background: white;
}

.input_edit {
  background: #f4f4f7;
  border: #dedee1 solid 1px;
  /* opacity: 0.4; */
  border-radius: 5px;
}

.border-border {
  border-left: 1px solid #d8dbe0;
  border-right: 1px solid #d8dbe0;
  border-bottom: 1px solid #d8dbe0;
}

.circular-progress-size {
  width: 60px;
  height: 60px;
}

/* .margin-left-design {
    margin-left: 30px;
} */

.button-size-budget {
  width: 120px;
}

.border-tabs-radios {
  border-top-left-radius: 5px !important;
}

.button-mobile-margin {
  margin-left: 50px;
}

.select-design {
  border-radius: 2px;
  border-color: #23303d;
  font-size: 12px;
  color: white;
}

.date-picker-design {
  width: 100px;
  font-size: 10.5px;
  border-width: 0px;
}

.multi-select-design {
  font-size: 10px;
  text-align: left;
}

.multi-select-design-recommender {
  font-size: 10px;
  text-align: left;
  border: 2px solid #549761;
  border-radius: 6px;
}

.list-font-style {
  font-size: 12px;
  color: rgb(206, 206, 206);
  font-style: italic;
  margin-top: 10px;
}

.red {
  background-color: #b42a19;
}

.yellow {
  background-color: #fdfe54;
}

.green {
  background-color: #3a7d21;
}

.form-boderless {
  border-width: 0px;
  border: none;
}

.nav-design-active {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 5px solid #549761 !important;
  color: #23303d !important;
}

.nav-design {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  /* border-bottom: 5px solid #549761!important; */
  color: #23303d !important;
}

.progress-total-val {
  position: absolute;
  top: 10px;
  left: 85%;
  color: #23303d;
}

/* .unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.date-picker-design.unstyled {
  width: 100%;
  font-size: 10.5px;
  border-width: 0px;
}

.margin_table_top {
  margin-top: 17px;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.margin_top_ss {
  margin-top: 15px;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.margin_table_top_tt {
  margin-top: 5px;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.margin_top_ss_tt {
  /* margin-top: 5px; */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

/* table design */
.view {
  margin: auto;
  width: 100%;
  height: 400px;
}

.wrapper {
  position: relative;
  overflow: auto;
  /* border: 1px solid black; */
  white-space: nowrap;
  margin-top: 20px;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: rgb(235, 235, 235) !important;
}

.first-col {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  left: 0px;
}

.third-last-col {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  left: 300px;
}

.second-last-col {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  left: 420px;
}

.last-col {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  left: 540px;
}

.upload_design input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}

.form-boderless_estimate {
  padding: 0px;
  width: 120px;
  height: 20px;
  color: #23303d;
  border-width: 0px;
  border: none;
  text-align: left;
  font-size: 14px;
}

.form-boderless_details {
  padding: 5px;
  width: 110px;
  height: 25px;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 3px;
}

.form-boderless_details :focus {
  border-color: #d8dbe0;
}

.td_width {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.percentage_width {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.modal-body-limit {
  height: 420px !important;
  overflow-y: scroll;
}

.dot-position {
  position: absolute;
  left: 85%;
  top: 40%;
}

.dot-position-sow {
  margin-top: 20px;
}

.active-paginate {
  background: #23303d;
  color: white;
}

.font-color-active {
  color: white;
}

/* updates page */

.time_post {
  font: normal normal normal 14px Roboto;
  text-align: right;
}

.name_post {
  font: normal normal bold 20px Roboto;
  margin-left: 15px;
}

.img_post {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.name_post {
  font: normal normal bold 14px Roboto;
  margin-left: 15px;
}

.img_post {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.img_comment {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.content_post {
  font: normal normal normal 14px Roboto;
  text-align: left;
}

.font-style {
  font: normal normal bold 14px Roboto;
  text-align: center;
}

.icon-size {
  width: 18px;
  height: 18px;
  color: #23303d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.comment-style {
  background-color: #dedee1;
  font: normal normal normal 14px Roboto;
  border-radius: 10px;
  padding: 10px;
}

.box {
  display: flex;
  align-items: stretch;
}

.read-less {
  height: 100px;
  overflow: hidden;
  background: linear-gradient(transparent 0px, white);
}

.read-more {
  height: auto;
}

/* .upload-button {
  display: none;
}

.upload-file {
  background: #ffffff;

  width: 100%;
  border: 0px;
  text-align: left;
  padding: 5px;
  color: #768192;
  font-size: 12px;

  margin-top: 20px;
} */

.cardHeader-style {
  border: 0px;
}

.write-post {
  width: 90%;
  color: gray;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  margin-left: 10px;
}

.reply-box {
  width: 100%;
  /* border: 1px solid gray;
  border-radius: 10px; */
  padding: 5px;
}

.post-card-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.file_text {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.div-image {
  position: relative;
}

.div-icon {
  position: absolute;
  right: 12px;
  top: 9px;
  color: #707070;
}

.div-icon-reply {
  position: absolute;
  right: 12px;
  color: #707070;
}

.upload-file {
  background: #ffffff;
  border-width: 1px;
  border-radius: 3px;
  width: 100%;
  border-style: solid;
  text-align: center;
  padding: 5px;
  color: #23303d;
  font-size: 12px;
  border-color: #23303d;
}

.upload-file-swa {
  background: #ffffff;
  width: 100%;
  border-style: hidden;
  text-align: left;
  padding: 5px;
  color: #23303d;
  font-size: 12px;
}

.upload-button {
  display: none;
}

/* .sticky {
  position: fixed;
  background-color: white;
  width: 100%;
  background-color: #e0e0e0;
} */

.border-top-hidden {
  border-top-style: hidden;
}

.back_button {
  height: 25px;
  width: 25px;
}

.back_button:hover {
  text-shadow: 2px 2px 4px #000000;
}

@media screen and (max-width: 480px) {
  .Total-label {
    white-space: nowrap;
    font-size: 12px;
    /* text-align: left; */
    font: normal normal normal Roboto;
    letter-spacing: 0px;
    color: #23303dc3;
    opacity: 1;
  }

  .project-start-text {
    text-align: left;
    font: normal normal bold 10px/14px Roboto;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
  }

  .project-end-text {
    text-align: right;
    font: normal normal bold 10px/14px Roboto;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
  }

  .sow-label {
    text-align: center;
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
    margin-left: 30px;
  }

  .circular-progress-size {
    width: 60%;
    height: 60%;
  }

  .input_number_spin::-webkit-inner-spin-button,
  .input_number_spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .align-center {
    text-align: center;
    margin-left: 50px;
  }

  .align-text-mobile {
    position: absolute;
    top: 90px;
    left: 60px;
  }

  /* .margin-left-design {
    margin-left: 30px;
} */

  .left-design {
    background: #23303d;
    opacity: 1;
    width: 10px;
    height: 305px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .mobile-class {
    margin-left: 90px;
  }

  .button-size-budget {
    width: 100px;
  }

  .button-mobile-margin {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .table-size {
    width: 100% !important;
    overflow-y: scroll;
  }

  .project-img-sub {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    opacity: 0.5;
    border-radius: 5px;
  }

  .tab-margin {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .projecttabs {
    border: 0.2px solid #23303d6c !important;
    opacity: 1 !important;

    border-radius: 0 !important;
    color: #23303d !important;
    height: 35px !important;
    width: 115px !important;
    text-align: center !important;
    font-size: 12px;
  }

  .projecttabs-active {
    border: 0.2px solid #23303d6c !important;
    opacity: 1 !important;
    border-radius: 0 !important;
    color: #ffffff !important;
    background: #23303d !important;
    height: 35px !important;
    width: 115px !important;
    text-align: center !important;
  }

  .tab-margin {
    margin-top: 20px;
  }

  .Total-label {
    white-space: nowrap;
    font-size: 10px;
    text-align: left;
    font: normal normal normal Roboto;
    letter-spacing: 0px;
    color: #23303dc3;
    opacity: 1;
  }

  .Total-value {
    font-size: 12px;
    text-align: right;
    font: normal normal bold Roboto;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
  }

  .card-days-left-value {
    font-size: 12px;
    text-align: center;
    font: normal normal bold Roboto;
    letter-spacing: 0px;
    color: #efefef;
    opacity: 1;
  }

  .card-days-left-label {
    font-size: 10px;
    white-space: nowrap;
    text-align: center;
    font: normal normal normal Roboto;
    letter-spacing: -1px;
    color: #efefef;
    opacity: 1;
  }

  /* .tab-margin-design {
    margin-left: 173px;
} */

  .left-design {
    background: #23303d;
    opacity: 1;
    width: 10px;
    height: 145px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .left-design-report {
    background: #23303d;
    opacity: 1;
    width: 10px;
    height: 145px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .sow-label {
    text-align: center;
    font: normal normal bold 14px/18px Roboto;
    letter-spacing: 0px;
    color: #23303d;
    opacity: 1;
    margin-left: 30px;
  }

  .tab-align {
    margin-left: 170px;
  }

  .project-img-sub {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    opacity: 0.5;
    border-radius: 5px;
  }

  .button-mobile-margin {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 100px;
  }
}

/* NEW CSS */

/* For SOW, Multi-Select Dropdown width */
.panel-content {
  width: 30vw;

  /* Media query for small screens */
  @media (max-width: 600px) {
    /* Styles for small screens */
    width: 100%;
  }
}

/* DatePicker CSS */
.react-date-picker__calendar-button svg {
  width: 10px;
  margin-left: -15px;
  margin-top: -4px;
}

.react-date-picker__clear-button svg {
  margin-left: 15px;
  display: none;
}

.react-date-picker {
  height: 20px;
  font-size: 10px;
}

/* FOR SOW(Dependency) */
.swal-icon-size {
  margin-top: 45px !important;
  font-size: 20px !important;
  margin-left: 120px !important;
}

.swaltitle {
  margin-top: -110px !important;
  margin-left: 100px !important;
}

.cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  /* background-color: #6e7d88; */
  background-color: #ffffff;
  /* color: #fff; */
  color: #23303d;
  font-size: 1em;
  margin: 0.3125em;
  padding: 0.525em 1.1em;
  /* Adjust the padding values to change the height */
  transition: box-shadow 0.1s;
  /* box-shadow: 0 0 0 3px #6e7d88; */
  box-shadow: 0 0 0 2px #23303d;
  font-weight: 500;
}

.confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  /* background-color: #6e7d88; */
  background-color: #23303d;
  color: #fff;
  font-size: 1em;
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  font-weight: 500;
}

/* FOR projectoverview Dates */
.sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
/* FOR projectoverview Dates */

/* FOR projectscopeofwork Dates */
.sow {
  width: 5.3rem;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
/* FOR projectscopeofwork Dates */

/* FOR reports Dates */
.reports {
  width: 100%;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
/* FOR reports Dates */

/* FOR notes/updates */
.notes {
  box-shadow: 0 0 0 0.5px black !important;
  color: black !important;
}
/* FOR notes/updates */

.expand-collapse {
  color: #39f;
  text-decoration: underline;
  cursor: pointer;
}

.expand-collapse :hover {
  opacity: 0.1;
}

.rmsc .react-multi-select-container .react-multi-select-input {
  border: 3px solid #ff8b67;
  border-radius: 5px;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(180, 42, 25, 0.7);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(180, 42, 25, 0);
  }
  100% {
    transform: scale(0.9);
  }
}
